<template>
  <div>
    <b-form-input v-if="type === 'number'" v-model.number="valueLocal" :placeholder="placeholder" :required="required" :disabled="edit"></b-form-input>
    <b-form-input v-if="type === 'text'" v-model="valueLocal" :required="required" :disabled="edit"></b-form-input>
    <!-- <b-form-input v-if="type === 'text'" v-model="valueLocal" :placeholder="placeholder" :required="required" :disabled="edit"></b-form-input> -->
    <b-form-textarea v-if="type === 'textarea'" v-model="valueLocal" :placeholder="placeholder" rows="6" :required="required" :disabled="edit"></b-form-textarea>
    <input v-if="type === 'date'" type="date" class="form-control" v-model="valueLocal" :placeholder="placeholder" :required="required" :disabled="edit"/>
    <treeselect v-if="type === 'select-model'" :multiple="isArray" :options="options" :clear-on-select="true" :matchKeys="['titre']" v-model="valueLocal" :required="required" :placeholder="placeholder" :disabled="edit">
      <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
        {{ node.raw.titre}}
      </label>
      <div slot="value-label" slot-scope="{ node }">{{node.raw.titre}}</div>
    </treeselect>
    <b-form-checkbox v-if="type === 'checkbox'" v-model="valueLocal" size="lg" :disabled="edit">{{placeholder}}</b-form-checkbox>
    <input v-if="type === 'price'" class="form-control" v-model.number="valueLocal" :placeholder="placeholder" :required="required" ref="inputPrice" @focus="focusPrice" @focusout="focusOutPrice"  :disabled="edit"/>
    <input v-if="type === 'timestamp'" type="date" class="form-control" v-model="timestamp"/>
  </div>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: "InputForm",
  props: {
    value: {
      required: true
    },
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    edit: {
      type: Boolean,
      required: false
    },
    keyname:{
      type: String,
      required: false
    },
  },
  model: {
    prop: 'value',
    event: 'inputChange'
  },
  computed: {
    isArray(){
      return Array.isArray(this.valueLocal)
    },
    valueLocal: {
      get() {
        return this.value ?? null;
      },
      set(value) {
        if(value === undefined){
          value = null;
        }
        this.$emit('inputChange', value)
      }
    },
    timestamp:{
      get(){
        var val = this.valueLocal;
        if(val === null){
          return null;
        }else{          
          return this.$api.moment(val * 1000).format("YYYY-MM-DD");
        }
      },
      set(v){
        if(v === null){
          this.valueLocal = null
        }else{          
          if(this.keyname.indexOf('min_') === 0){
            v += ' 00:00:00';
          }else if(this.keyname.indexOf('max_') === 0){
            v += ' 23:59:59';
          }
          this.valueLocal = parseInt(this.$api.moment(v).unix())
        }
      }
    }
  },
  methods: {
    formatPrice(){
      return this.valueLocal.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      });
    }, 
    focusPrice(){
      this.$refs.inputPrice.value = this.valueLocal;
    },
    focusOutPrice(){
      var v = this.$refs.inputPrice.value;
      v = Number(v.replace(/[^0-9.-]+/g,""));
      this.valueLocal = v;
      this.$refs.inputPrice.value = this.formatPrice();
    }
  },
  mounted() {    
    if(this.type === 'price' && this.valueLocal != null){
      this.$refs.inputPrice.value = this.formatPrice();
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
